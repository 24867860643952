import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { ContainerPdf, TransmitModal } from './styles';
import { LeitorPDF } from '../LeitorPDF';
import { AiOutlineDownload, AiOutlinePrinter } from 'react-icons/ai';

interface Props {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  pdf: any;
}

export const TransmissaoModal: React.FC<Props> = ({
  showModal,
  setShowModal,
  pdf,
}) => {
  const getChaveAcesso = (byteArray: Uint8Array) => {
    // Convertendo a representação de bytes para uma string
    const pdfString = Buffer.from(byteArray).toString('utf8');

    // Definindo a expressão regular para encontrar a chave de acesso
    const chaveAcessoRegex =
      /\b\d{4}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{4}\b/g;

    // Encontrando todas as correspondências na string do PDF
    const matches = pdfString.match(chaveAcessoRegex);

    // Verificando se há correspondências
    if (matches && matches.length > 0) {
      // Retornando a primeira correspondência encontrada
      return matches[0].replace(/\s/g, ''); // Removendo espaços em branco, se houver
    }
  };

  const handleDownload = async (pdfBuffer: any) => {
    try {
      if (pdfBuffer) {
        // Convertendo o buffer para um array de bytes
        const byteArray = new Uint8Array(pdfBuffer.data);

        const blob = new Blob([byteArray], { type: 'application/pdf' });

        // const blob = new Blob([pdfBuffer], { type: 'application/pdf' });

        // Cria um link temporário
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);

        const fileName = getChaveAcesso(byteArray) || 'danfe';

        downloadLink.download = `${fileName}.pdf`;
        downloadLink.click();
        return;
      }
      toast.warn('Não foi possível baixar a DANFE');
    } catch (error) {
      toast.error(`Não foi possível baixar a DANFE: ${error}`);
    }
  };

  const handlePrinter = useCallback(async () => {
    try {
      const byteArray = new Uint8Array(pdf.data);
      const blob = new Blob([byteArray], { type: 'application/pdf' });

      const fileURL = URL.createObjectURL(blob);
      const pdfWindow = window.open();
      if (pdfWindow) {
        pdfWindow.location.href = fileURL;
      }
    } catch (error) {
      return toast.error((error as Error).message);
    }
  }, [pdf]);

  return (
    <>
      <TransmitModal
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="custom-modal"
      >
        <Modal.Header>
          <Modal.Title>DANFE</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ContainerPdf>{pdf && <LeitorPDF pdf={pdf} />}</ContainerPdf>
        </Modal.Body>
        <Modal.Footer>
          <div className="actions">
            <div className="left">
              <Button
                className="btn-impressao"
                onClick={handlePrinter}
                title="Imprimir"
              >
                Imprimir
                <AiOutlinePrinter size={24} className="btn-icon" />
              </Button>
              <Button
                onClick={() => {
                  handleDownload(pdf);
                }}
                title="download"
              >
                Download
                <AiOutlineDownload size={24} className="btn-icon" />
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </TransmitModal>
    </>
  );
};
