import React, { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import useTransmissao from '../../useTransmissao';

interface Props {
  pdf: any;
}

export const LeitorPDF: React.FC<Props> = ({ pdf }) => {
  const [numPage, setNumPage] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPage(numPages);
    setPageNumber(1);
  }

  return (
    <>
      <Document
        className="pdf"
        renderMode="svg"
        loading=""
        file={pdf}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page renderMode="svg" width={1080} pageNumber={pageNumber} />
      </Document>
    </>
  );
};
