import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { ListProps } from '../protocols';

import { Option } from '../styles';

export const Lista: React.FC<ListProps> = ({
  inputRef,
  setIsOpen,
  setFilter,
  hasNextPage,
  fetchNextPage,
  data,
  selected,
  setSelected,
  isFetching,
  changeSelected,
  queryName,
  queryClient,
}) => {
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [mouseFocusedIndex, setMouseFocusedIndex] = useState(-1);

  /**
   * wrapperRef
   * Ref da lista de opções utilizado para saber quando o final da lista foi atingido
   */
  const wrapperRef = useRef<HTMLUListElement | null>(null);

  const onResetStates = useCallback(() => {
    setFocusedIndex(-1);
    setMouseFocusedIndex(-1);
  }, []);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const wrapperElement = wrapperRef.current;
      if (!wrapperElement) return;

      const opts = wrapperElement.getElementsByTagName(
        'li',
      ) as HTMLCollectionOf<HTMLLIElement>;
      if (!opts.length) return;

      setSelected({ value: '', label: '' });

      const pageSize = Math.floor(
        wrapperElement.clientHeight / opts[0].clientHeight,
      );

      const handleNavigation = (newIdx: number) => {
        setFocusedIndex(Math.max(0, Math.min(newIdx, opts.length - 1)));
      };

      const keyActions: Record<string, () => void> = {
        ArrowDown: () => handleNavigation(focusedIndex + 1),
        ArrowUp: () => handleNavigation(focusedIndex - 1),
        PageDown: () => handleNavigation(focusedIndex + pageSize),
        PageUp: () => handleNavigation(focusedIndex - pageSize),
        Home: () => handleNavigation(0),
        End: () => handleNavigation(opts.length - 1),
        Enter: () => {
          const activeIndex =
            focusedIndex >= 0 && focusedIndex < opts.length
              ? focusedIndex
              : mouseFocusedIndex;
          if (activeIndex >= 0 && activeIndex < opts.length) {
            opts[activeIndex].click();
            (document.activeElement as HTMLElement)?.blur();
          }
        },
      };

      if (keyActions[event.key]) {
        event.preventDefault();
        keyActions[event.key]();
      }
    },
    [focusedIndex, mouseFocusedIndex, setSelected],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  useEffect(() => {
    const wrapperElement = wrapperRef.current;
    if (!wrapperElement) return;

    const opts = wrapperElement.getElementsByTagName('li');
    if (focusedIndex < 0 || focusedIndex >= opts.length) return;

    const focusedOption = opts[focusedIndex] as HTMLElement;
    const { clientHeight: wrapperHeight } = wrapperElement;
    const { offsetTop: optionOffsetTop, offsetHeight: optionHeight } =
      focusedOption;

    wrapperElement.scrollTop =
      optionOffsetTop - wrapperHeight / 2 + optionHeight / 2;
    focusedOption.focus();
  }, [focusedIndex, wrapperRef, data]);

  useEffect(() => {
    const preventScroll = (e: WheelEvent) => {
      if (wrapperRef.current && wrapperRef.current.contains(e.target as Node))
        setFocusedIndex(mouseFocusedIndex);
    };

    document.addEventListener('wheel', preventScroll, { passive: false });
    return () => document.removeEventListener('wheel', preventScroll);
  }, [mouseFocusedIndex, wrapperRef]);

  useEffect(() => {
    const wrapperElement = wrapperRef.current;
    if (!wrapperElement) return;

    const opts = Array.from(
      wrapperElement.getElementsByTagName('li'),
    ) as HTMLLIElement[];
    if (!opts.length || !selected) return;

    opts.forEach((opt, idx) => {
      if (opt.textContent === selected.label) {
        setMouseFocusedIndex(idx);
        setFocusedIndex(idx);
      }
    });
  }, [selected]);

  /**
   * useCloseOptions
   * Fecha lista de opções ao clicar fora
   */
  function useCloseOptions(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (inputRef) inputRef.current.value = '';
          setFilter('');
          queryClient.removeQueries(queryName);
          setIsOpen(false);
          onResetStates();
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }
  useCloseOptions(wrapperRef);

  /**
   * onScroll
   * Verifica se o scroll chegou ao final da lista de opções
   */
  const onScroll = async () => {
    if (wrapperRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = wrapperRef.current;
      const zoomLevel = Math.round(window.devicePixelRatio * 100);
      const offset = Math.abs(100 - zoomLevel); // essa é a margem que mencionei
      if (scrollTop + clientHeight >= scrollHeight - offset) {
        if (hasNextPage) {
          await fetchNextPage();
        }
      }
    }
  };

  /**
   * handleClick
   * Seleciona item da lista
   */
  const handleClick = useCallback(
    (option: { label: string; value: number }) => {
      setSelected(option);
      changeSelected(option);
      setIsOpen(false);
      onResetStates();
    },
    [changeSelected, setIsOpen, setSelected, onResetStates],
  );

  const formatCpfCnpj = (num_cpf_cnpj: string) => {
    let cgc = '';
    if (num_cpf_cnpj) {
      if (num_cpf_cnpj?.length === 11) {
        cgc = num_cpf_cnpj.replace(
          /(\d{3})(\d{3})(\d{3})(\d{2})/g,
          '$1.$2.$3-$4',
        );
      } else {
        cgc = num_cpf_cnpj?.replace(
          /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
          '$1.$2.$3/$4-$5',
        );
      }
    }
    return cgc;
  };

  /**
   * Formatter pro nome do parceiro
   */
  const formatName = (value: any) => {
    let formatLabel = '';
    const formatCidade = value.des_cidade ? value.des_cidade : '';
    const formatUf = value.des_uf ? value.des_uf : '';
    if (value.des_cidade && value.des_uf) {
      formatLabel = `${value.nome_pessoa} (${formatCidade} - ${formatUf})`;
    } else {
      formatLabel = value.nome_pessoa;
    }
    return formatLabel;
  };

  /**
   * renderOptions
   * Renderiza lista de opções
   */
  const renderOptions = useCallback(() => {
    /**
     * Caso a variavel data não seja undefined
     */
    if (data !== undefined) {
      /**
       * Caso existam dados de acordo com a busca efetuada
       * os mesmos serão renderizados na lista de opções.
       * Caso contrario entrarão na condicional abaixo exebindo
       * "Nenhum registro encontrado."
       */
      if (!data.pages[0].message) {
        return (
          <Option className="not-found">
            Digite ao menos 3 caracteres para pesquisar.
          </Option>
        );
      }
      if (data.pages[0].message === 'url not found') {
        return (
          <Option className="not-found">Nenhum registro encontrado.</Option>
        );
      }
      if (data.pages.length <= 0) {
        return (
          <Option className="not-found">Nenhum registro encontrado.</Option>
        );
      }
      if (
        data.pages[0].message === 'Registros encontrados' &&
        data.pages[0].data.length <= 0
      ) {
        return (
          <Option className="not-found">Nenhum registro encontrado.</Option>
        );
      }
      return data.pages.map((page) => {
        return page.data.map((item: any, idx: number) => {
          const itemClassNames = [
            selected?.value === item?.value ? 'selected' : '',
            focusedIndex === idx ? 'focused' : '',
          ]
            .filter(Boolean)
            .join(' ');

          return (
            <Option
              key={item.value}
              className={itemClassNames}
              onMouseEnter={() => setMouseFocusedIndex(idx)}
              onMouseLeave={() => setMouseFocusedIndex(-1)}
              onClick={() => {
                handleClick(item);
                if (inputRef) {
                  inputRef.current.placeholder = item.label;
                  inputRef.current.value = '';
                }
                queryClient.removeQueries(queryName);
                setFilter('');
              }}
              onKeyPress={() => {
                handleClick(item);
                if (inputRef) {
                  inputRef.current.placeholder = item.label;
                  inputRef.current.value = '';
                }
                queryClient.removeQueries(queryName);
                setFilter('');
              }}
            >
              <b className="cpf-cnpj">{formatCpfCnpj(item.num_cpf_cnpj)}</b>
              <br />
              {formatName(item)}
            </Option>
          );
        });
      });
    }
    /**
     * Caso não existam dados de acordo com a busca efetuada
     * será listada a opção abaixo
     */
    return <Option className="not-found">Nenhum registro encontrado.</Option>;
  }, [
    data,
    handleClick,
    inputRef,
    queryClient,
    queryName,
    selected.value,
    setFilter,
    focusedIndex,
  ]);

  return (
    <ul ref={wrapperRef} onScroll={onScroll}>
      {data
        ? renderOptions()
        : !isFetching && (
            <Option className="not-found">
              Digite ao menos 3 caracteres para pesquisar.
            </Option>
          )}
      {isFetching && (
        <Option className="not-found">
          <Spinner animation="border" size="sm" className="spinner" />
        </Option>
      )}
    </ul>
  );
};
