import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

export const ContainerPdf = styled.div`
  width: 100%;
  height: 450px;
  overflow: auto;
  border: 1px solid #dfdfdf;
`;

export const TransmitModal = styled(Modal)`
  .custom-modal {
    max-width: 68%;
    margin: auto;
  }
  .modal-header {
    height: 100px;
    padding: 0px 80px;
    background: #fff;
    color: #58079e;
    border-bottom: transparent;

    .modal-title {
      margin-top: auto;
      width: 100%;
      /* background: red; */
      border-bottom: 1px solid #dee2e6;
      /* text-align: right; */
    }
  }
  .modal-body {
    margin-top: 20px;
    padding-left: 80px;
    padding-right: 80px;

    .modal-acoes {
      height: 70px;
      transition: 0.3s;
      cursor: pointer;

      & + .modal-acoes {
        border-top: 1px solid #ededed;
      }
      & + a {
        border-top: 1px solid #ededed !important;
      }
      h5 {
        font-size: 18px;
        color: #474747;
      }
      small {
        font-size: 13px;
      }
      svg {
        color: ${(props) => props.theme.colors.nav} !important;
      }
    }
    .executando {
      background: #ebebeb;
      transition: 0.3s;

      cursor: default;
      h5 {
        color: #a19d9d !important;
      }
      small {
        color: #a19d9d !important;
      }
      svg {
        color: #9d7dbd !important;
      }
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    }
    .nav-tabs {
      border: none;
      .nav-item {
        display: none;
        visibility: hidden;
      }
    }
    .button-confirm {
      margin-top: 31px;
    }
  }
  .modal-footer {
    padding-left: 80px;
    padding-right: 80px;
    margin-top: 50px;

    .actions {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;

      .left {
        display: flex;
        gap: 4px;

        .btn-impressao {
          background-color: #2d136c;
          border-color: #200e49;
          &:focus {
            box-shadow: 0 0 0 0.25rem #c2a0f8;
          }
        }
      }
      .right {
      }
    }
  }
`;
