import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import api from '~/services/api';
import { NfeData } from './protocols/transmissao.props';
import { toast } from 'react-toastify';

const MySwal = withReactContent(Swal);

interface UseTransmissaoProps {
  transmitirNFe: (nfeData: NfeData) => Promise<void>;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  pdf: any;
  setPdf: Dispatch<any>;
}

export default function useTransmissao(): UseTransmissaoProps {
  const [showModal, setShowModal] = useState(false);
  const [pdf, setPdf] = useState<any>(null);

  const transmitirNFe = useCallback(async (nfeData: NfeData) => {
    const pdfBuffer = await MySwal.fire({
      title: 'NF Gravada com sucesso',
      text: 'Deseja transmiti-la?',
      showDenyButton: true,
      confirmButtonColor: '#0065FF',
      denyButtonColor: '#DE350B',
      confirmButtonText: 'Sim',
      denyButtonText: 'Não',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        const denyButton = Swal.getDenyButton();
        const cancelButton = Swal.getCancelButton();

        if (denyButton) {
          denyButton.style.display = 'none';
        }
        try {
          const res = await api.post('/transmitir-nfe', {
            cod_loja: nfeData.cod_loja,
            cod_pessoa: nfeData.cod_pessoa,
            num_nf: nfeData.num_nf,
            tipo_nf: nfeData.tipo_nf,
            cod_seq_nf: nfeData.cod_seq_nf,
          });

          if (res.data.success) {
            return res;
          }
        } catch (error: any) {
          const confirmButton = Swal.getConfirmButton();
          if (confirmButton) {
            confirmButton.textContent = 'Tentar Novamente';
          }

          if (cancelButton) {
            cancelButton.style.display = 'inline-block';
            cancelButton.textContent = 'Tentar Mais Tarde';
            cancelButton.style.backgroundColor = '#cf9d08';
          }
          Swal.showValidationMessage(`
            ${error.data.error || error.data.message}
          `);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result.isConfirmed) {
        return result.value.data.danfe;
      }
      if (result.isDismissed && !result.isConfirmed && !result.isDenied) {
        toast.warn(
          'Vá em Manutenção Fiscal, faça as correções necessárias, depois volte aqui e tente transmitir novamente.',
        );
      }
      return null;
    });
    if (pdfBuffer) {
      setShowModal(true);
      setPdf(pdfBuffer);
    }
  }, []);

  return {
    transmitirNFe,
    showModal,
    setShowModal,
    pdf,
    setPdf,
  };
}
