import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import useDebounce from '~/hooks/useDebounce';
import api from '~/services/api';

import { tiposDeBuscaDefault } from './default';
import { Lista } from './Lista';
import { BuscaParceiroProps } from './protocols';
import { InputContainer } from './styles';

/**
 * @param {BuscaParceiroProps} BuscaParceiro - sla né
 */

export const BuscaParceiro: React.FC<BuscaParceiroProps> = ({
  label = '',
  placeholder = '',
  name,
  register,
  isError,
  control,
  changeSelected,
  customOptions,
  codLoja,
  isDisabled = false,
  ...rest
}: BuscaParceiroProps) => {
  /**
   * debouncedFn
   * Hook de Debounce usado para efetuar a busca após digitação do usuário
   */
  const { debouncedFn } = useDebounce();
  /**
   * inputRef
   * Ref do input utilizado para setar a label da opção selecionada no input
   */
  const inputRef: any = useRef(null);
  /**
   * selected, setSelected
   * Opção selecionada da lista
   */

  const [selected, setSelected] = useState<any>({
    label: undefined,
    value: undefined,
  });
  /**
   * isOpen
   * Renderiza ou não a lista
   */
  const [isOpen, setIsOpen] = useState<boolean>(false);
  /**
   * filter
   * Filtro passado para a api
   */
  const [filter, setFilter] = useState<string>('');
  /**
   * limit
   * Quantidade de registros buscados por vez na api
   */
  const limit = 10;
  /**
   * fetchData
   * Método que se comunica com a api
   */

  const fetchData = useCallback(
    async ({ pageParam = 1 }): Promise<any> => {
      try {
        if (filter === '' && !customOptions?.searchBeforeFilter) {
          return {
            count: '0',
            data: [],
            fields: [],
            pagination: {
              lastPage: 0,
              prevPage: 1,
              startPage: 1,
            },
            success: true,
          };
        }
        const { data: dataFields } = await api.get('/parceiros', {
          params: {
            page: pageParam,
            limit,
            tiposDeBusca: customOptions?.buscarPor ?? tiposDeBuscaDefault,
            filter,
            codLoja,
          },
        });

        const options = dataFields.data
          .filter((d: any) => !d.flg_inativo)
          .map((dt: any) => {
            const value = dt.cod_pessoa;
            const stringLabel = `${dt.nome_pessoa} ${
              dt.num_cpf_cnpj ? `(${dt.num_cpf_cnpj})` : ''
            }`;
            let objLabel = '';

            const { fields } = customOptions ?? {};

            if (fields?.length) {
              switch (fields.length) {
                case 1:
                  objLabel = `${dt[fields[0]] ?? value} - ${stringLabel}`;
                  break;
                case 2:
                  objLabel = `${dt[fields[1]] ?? value} - ${stringLabel}`;
                  break;
                default:
                  objLabel = `${fields
                    .map((field) => dt[field])
                    .join(' - ')} - ${stringLabel}`;
                  break;
              }
            }

            return {
              ...dt,
              value,
              label: objLabel || `${value} - ${stringLabel}`,
            };
          });

        return {
          ...dataFields,
          data: options,
        };
      } catch (error: any) {
        if (error.data?.message) {
          toast.error(error.data.message);
        } else {
          toast.error(String(error));
        }
        return {
          count: '0',
          data: [],
          fields: [],
          pagination: {
            lastPage: 0,
            prevPage: 1,
            startPage: 1,
          },
          success: true,
        };
      }
    },
    [
      customOptions?.buscarPor,
      customOptions?.searchBeforeFilter,
      codLoja,
      filter,
    ],
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (isOpen && event.key === 'Tab') setIsOpen(false);
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [isOpen]);

  /**
   * useInfiniteQuery
   * Hook do react-query para listagem infinita de dados
   */
  const queryClient = useQueryClient();
  const { data, hasNextPage, fetchNextPage, isFetching, refetch } =
    useInfiniteQuery(name, ({ pageParam = 1 }) => fetchData({ pageParam }), {
      getNextPageParam: (lastPage, allPages) => {
        const maxPages = lastPage.pagination.lastPage + 1;
        const nextPage = allPages.length + 1;
        return nextPage <= maxPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    });

  return (
    <>
      <InputContainer>
        <Form.Group>
          <Form.Label>{label}</Form.Label>
          <Controller
            name={name}
            control={control}
            defaultValue=""
            render={({ field: { value } }) => {
              if (inputRef && inputRef.current) {
                /** A condicional abaixo limpa o input */
                if (
                  value.label !== selected.label &&
                  inputRef.current.placeholder !== placeholder
                ) {
                  inputRef.current.placeholder = placeholder;
                }

                if (value.label) {
                  inputRef.current.placeholder = `${value.label}`;
                }
                if (!value.label) {
                  inputRef.current.placeholder = placeholder;
                }
              }
              return (
                <>
                  <div className="select-container">
                    <label>
                      <input
                        {...register(name)}
                        type="text"
                        className={
                          isError ? 'form-control is-invalid' : 'form-control'
                        }
                        disabled={isDisabled}
                        placeholder={placeholder}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          event.target.value = event.target.value.toUpperCase();
                          setFilter(
                            event.target.value
                              .toUpperCase()
                              .replace(/[.\-/]/g, ''),
                          );
                          if (event.target.value === '') {
                            queryClient.removeQueries(name);
                          }
                          debouncedFn(() => refetch(), 500);
                        }}
                        autoComplete="off"
                        onClick={(event: any) => {
                          event.target.value = '';
                          setIsOpen(true);
                        }}
                        ref={inputRef}
                        onFocus={() => setIsOpen(true)}
                        {...rest}
                      />
                      <div className="drop-indicator">
                        <span role="img" aria-label="open">
                          {filter && isFetching ? (
                            <Spinner
                              animation="border"
                              size="sm"
                              className="spinner"
                            />
                          ) : (
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              role="presentation"
                            >
                              <path
                                d="M8.292 10.293a1.009 1.009 0 000 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 000-1.419.987.987 0 00-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 00-1.406 0z"
                                fill="currentColor"
                                fillRule="evenodd"
                              />
                            </svg>
                          )}
                        </span>
                      </div>
                    </label>
                  </div>
                  {isOpen && (
                    <Lista
                      inputRef={inputRef}
                      setIsOpen={setIsOpen}
                      setFilter={setFilter}
                      hasNextPage={hasNextPage}
                      fetchNextPage={fetchNextPage}
                      data={data}
                      selected={selected}
                      setSelected={setSelected}
                      isFetching={isFetching}
                      queryName={name}
                      changeSelected={changeSelected}
                      queryClient={queryClient}
                    />
                  )}
                </>
              );
            }}
          />
        </Form.Group>
      </InputContainer>
    </>
  );
};
