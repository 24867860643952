import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  createContext,
} from 'react';

import useTransmissao from './useTransmissao';
import { NfeData } from './protocols/transmissao.props';
import { TransmissaoModal } from './components/TransmissaoModal';

interface ContextProps {
  transmitirNFe: (nfeData: NfeData) => Promise<void>;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  pdf: any;
  setPdf: Dispatch<any>;
}
const Context = createContext<ContextProps>({} as ContextProps);

const TransmissaoProvider: React.FC = ({ children }) => {
  const { transmitirNFe, showModal, setShowModal, pdf, setPdf } =
    useTransmissao();

  return (
    <Context.Provider
      value={{
        transmitirNFe,
        pdf,
        setPdf,
        showModal,
        setShowModal,
      }}
    >
      {children}
      <TransmissaoModal
        showModal={showModal}
        setShowModal={setShowModal}
        pdf={pdf}
      />
    </Context.Provider>
  );
};

export { Context, TransmissaoProvider };
